.welcomeAnimation {
  align-items: center;
  justify-content: center;
  height: 75vh;
  background-color: transparent;

  &.row {
    > * {
      width: unset;
    }
  }
}
