body {
  overflow: visible;
  position: relative;

  &.enlarged .left-side-scroll {
    overflow: visible !important;

    & > div:first-child {
      margin: 0 !important;
      overflow: visible !important;
    }

    & > div:last-child {
      display: none;
    }
  }
}

#wrapper {
  overflow: visible;

  .navbar-custom {
    top: 0;
    box-shadow: none;

  }

  .content-page {
    overflow: visible;
  }

  .menu-toggler {
    display: none
  }

  @media (max-width: 835px) {
    .menu-toggler {
      display: block;
    }
  }

  .logo-box {
    .logo {
      padding-left: 1rem;
    }
  }

}

.navbar-custom {
  .topnav-menu .nav-link {
    color: $white;
  }

  .active-task {
    margin-left: 1rem;
    height: 55px;
    display: inline-block;
    color: #fff;
    margin-top: 7px;
    font-size: 0.7rem;
    padding: 2px 10px;
    border: 1px solid #fff;
    min-width: 100px;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
  }
}

.enlarged .left-side-menu {
  top: 0;
}

.left-side-menu {
  padding-top: 0;
}


#sidebar-menu {
  &.custom {
    & > ul > li {
      &:not(.mm-active):not(:hover) a:not(.active) {
        color: $menu-item;
      }

      & > a {
        font-size: 13.2px;
        padding: 10px 20px 10px 10px;

        &.btn {
          text-align: inherit;
        }
      }

      .tt {
        color: #6e768e;
        font-weight: 500;

        &:hover {
          color: #0B1957;
        }

        &.active {
          background: #f0f0f0;
        }

        i {
          color: #0B1957;
        }

        i.fe-external-link {
          margin-right: -10px;
        }
      }

      .badge.badge-pill {
        margin-right: -10px;
        border-radius: 10rem;
        padding-inline: 0.6em;
      }
    }

    .menu-title {
      padding-left: 15px;
      letter-spacing: 0.15rem;
    }

    .nav-second-level li a {
      &.active {
        color: $blue;
      }
    }
  }
}

.badge {
  &.badge-dark-light {
    background: #aaa;
  }

  &.badge-draft {
    background: $draft;
  }

  &.badge-prelim {
    background: $prelim;
  }

  &.badge-primary {
    background: $blue-40;
  }

  &.badge-unpaid {
    background: $unpaid;
  }

  &.badge-sentBank {
    background: $sentBank;
  }
}

.authentication-bg {
  background: #2b3442;
  height: 100%;

  #wrapper {
    overflow: hidden;
    height: 100%;
    position: relative;
  }

  #auth-wrapper {
    i {
      color: #fafafa;
    }

    .system-notification-bar {
      margin-bottom: 1rem;
    }

    input {
      background-color: #333e4e;
      color: #98a6ad;
      border: 1px solid #3b475b !important;

      &::placeholder {
        color: #6e768e;
        letter-spacing: normal;
      }

      &:focus {
        background-color: #354051;
        border-color: #3f4c61;
      }
    }

    .form-control:invalid,
    .form-control.is-invalid {
      background-image: none;
    }

    label {
      color: #eee;
    }

    input.bankid {
      font-size: 1rem;
      letter-spacing: 0.1rem;
      background: #eee;
      border: none;
      color: #444;
    }

    .initial-ssn {
      .static-ssn {
        font-size: 2rem;
        color: #ccc;
        letter-spacing: 0.5rem;
      }
    }

    .language-switcher {
      text-align: center;
    }

    .bankId-logo {
      margin: 0 auto;
      height: 140px;
      width: 140px;
      text-align: center;
      padding-top: 18px;

      img {
        height: 90px;
      }
    }
  }

  .page-not-found {
    h1 {
      color: #fff;
    }
  }
}

footer.footer {
  z-index: 99;
  position: fixed;
  padding-top: 10px;
  padding-bottom: 10px;

  .copyrights {
    display: flex;
    align-items: center;
  }
}

#bank-match {
  table {
    .custom-checkbox.checked {
      background-color: #22429a;
      border-color: #22429a;
    }
    tr.date-row th {
      z-index: 5;
      background: $gray-100 !important;
      font-weight: 600 !important;
    }

    td.matched {
      background: $success;

      > div {
        display: none !important;
      }
    }

    .div-actions {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .th-bank-checkbox {
      text-align: left !important;

      .checkbox {
        padding-left: 1.18rem;
      }
    }
  }

  .matching-summary {
    border-top: 2px solid $primary;

    .form-group {
      margin: 0;
    }

    .manual-match {
      text-align: right;
    }
  }
}

.verification-files {
  .dropzone-wrapper.file-selected {
    height: auto;
  }

  .alert {
    margin-bottom: 0;
  }

  .dropzone-wrapper .dropzone-preview object[type="application/pdf"] {
    height: 80vh;
    overflow-x: hidden;
  }

  .document-box object {
    height: 80vh !important;
  }
}

.bmd-list {
  footer {
    left: 0;
  }
}

.company-list {

  table.table-loader {
    padding-top: 200px;
  }

  table.table.sticky-header thead th {
    top: 69px;
  }
}

body.file-preview-page {
  overflow: hidden;
  padding-bottom: 0;
  height: 100%;
  width: 100%;

  .content-page {
    padding-top: 10px;
  }
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 1rem;
}

.office-support-layout {
  &.content-page {
    background: $backgroundGrey;
  }

  .nav {
    background: $backgroundGrey;
    border-bottom: none !important;
  }

  form {
    .card {
      border: none;
      border-radius: 10px;
      box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.1);

      .card-header {
        border-radius: 10px;
        margin-top: 2px;
        margin-bottom: 2px;

        .card-title {
          margin: 0;
        }
      }
    }

    .btn:disabled {
      background-color: $disabledBtnGrey;
      border-color: $disabledBtnGrey;
      opacity: 1;
    }

    .cancel-btn {
      color: $darkGrey;
      font-size: 13px;
      line-height: 15px;
      font-weight: 500;
    }
  }
}