@import "assets/scss/new_ui/colors";

.approval-flow-wrapper {
  .approval-flow-step-wrapper {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 5px;

    .approval-flow-step-header {
      position: relative;
      margin-bottom: 16px;

      .approval-flow-step-title {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.01em;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        text-align: left;
      }

      .approval-flow-step-bar {
        position: relative;
        height: 3px;
        background: $orange-60;

        &.active-step {
          background: #757575;
        }

        &.completed-step {
          background: #4CAF50;
        }
      }

      .approval-flow-step-marker {
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $blue-40;
      }
    }

    .approval-flow-step-body {
      display: flex;
      flex-direction: column;
      border: 1px dashed #DEE2ED;
      background: #F7F7F7;
      width: auto;
      height: 64px;
      align-items: center;
      justify-content: center;
      gap: 2px;
      border-radius: 2px;

      &.active-step-body {
        outline: 2px solid #8fa8ec; // Highlight for the active body
      }

      .approval-flow-approvers-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .min-approvers-badge {
        margin: 0;
      }
    }
  }

  .approval-flow-step-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:30px;

    .arrow {
      font-size: 18px;
      color: $grey-60;
      align-self: center;
    }
  }

  .approval-flow-add-step-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .approval-flow-add-step {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    color: $blue-40;

    &:hover {
      background-color: $blue-6;
    }

    &:focus {
      outline: 2px solid $blue-20;
    }

    &:disabled {
      color: $blue-10;
      cursor: not-allowed;
      background-color: transparent;
    }

    &:active,
    &.active {
      border: 1px solid #8FA8EC
    }
  }

  .approval-flow-card {

    .row > * {
      width: unset;
    }

    .card-footer {
      border-top: none !important;
    }
  }
}
