#company-archive {
  .grid-1 {
    .card li {
      display: inline-block;
      margin-right: 2.5rem;
      height: 2rem;

      &:last-child {
        margin-right: 1rem;
      }
    }

    div:has(#checkbox) {
      display: flex;
      justify-content: flex-end;
    }
  }

  #filters {
    #checkbox {
      margin-bottom: 10px;
    }
  }

  .note {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .upload-btn {
    border-left: 1px solid #ECEDED;
    padding-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }

  .grid-2 {
    .card-body {
      align-items: center;
    }

    .upload-btn {
      border-left: none;
    }
  }
}

.draggable {
  position: relative;
  padding: 4px;
  margin-bottom: 1rem;


  .draggable-layer {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(52, 140, 212, 0.15);
    border: 2px dashed #ADADAD;
    border-radius: 3px;
    padding: 4px;
    z-index: 999;
  }
}

.archive-category-card.card {
  position: relative;
  border-top: 1px solid #e4e7ea;
  border-radius: 0;
  margin-bottom: 0;
  z-index: 1;


  .card-header {
    background: #F7F7F7;
    color: #0B1957;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    padding: 10px 15px;
  }

  .card-body {
    padding: 0;
    font-size: 13px;
    display: flex;
    justify-content: space-between;

    ul {
      padding: 10px 15px;
      margin: 0;
      list-style-type: none;

      li {
        font-size: 14px;
        line-height: 26px;

        &.archived {
          background-color: #e6e6e6;
          border-radius: 6px;
          padding: 4px 8px;
        }

        .share {
          .fe-clipboard {
            font-weight: 600;
            display: inline-block;
            color: #8892d6;
            margin-right: .5rem;
          }

          .fe-user {
            display: inline-block;
            color: #0B1957;
            margin-right: .5rem;
          }
        }

        .details {
          cursor: pointer;

          &:hover {
            color: #444;
          }

          span {
            text-decoration: underline;
          }
        }

        .edit-btn {
          font-size: 16px;
          cursor: pointer;
          color: #0B1957;
          margin-left: 15px;
          margin-right: 8px;

          &:hover {
            color: #2570ae;
          }
        }

        .del-btn {
          font-size: 17px;
          cursor: pointer;
          color: #ADADAD;

          &:hover {
            color: #F93636;
          }
        }

        > * {
          display: inline-block;
        }
      }
    }

    .fetch-btn {
      margin-left: auto;
    }
  }
}