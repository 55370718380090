body.modal-open.scrollable {
  overflow-y: scroll !important;
}

.modal-dialog-scrollable {
  .modal-content {
    overflow-y: auto;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-open .modal {
  overflow-y: hidden;
}

.modal {
  margin: 0;
  height: 100%;
  max-width: none;
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  pointer-events: none;

  .vh-100 {
    height: 100vh;
  }

  .modal-dialog {
    pointer-events: none;
  }

  .modal-content {
    border: 1px solid $gray-400;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    pointer-events: auto;
  }

  .modal-title {
    font-weight: normal;
    font-size: 1rem;
  }

  .modal-header {
    position: relative;
    cursor: grab;

    .btn-close {
      outline: none;
      position: absolute;
      right: 1.5rem;
      top: .7rem;
      background-image: none;
      padding: 0;
      margin: 0;

      &:before {
        outline: none;
        content: "×";
        font-size: 2.2rem;
        line-height: 1;
      }
      &:focus,
      &:active {
        box-shadow: none;
      }
    }

    &:active {
      cursor: grabbing;
    }
  }

  .no-border-bottom {
    border-bottom: 0;
  }

  object[type="application/pdf"] {
    min-height: 700px;
  }
}

.comments {
  .comments-item {
    position: relative;
    background: #f7f7f7;
    padding: 1rem;
    margin-bottom: 15px;

    &:hover .btn-remove {
      display: block;
    }

    .btn-remove {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 0;
    }
  }
}

.verification-modal {
  margin: 80px auto 0 auto;
  min-height: 400px;
  height: calc(100% - 100px);
  max-width: none;
  width: calc(100% - 40px);

  .modal-content {
    border-radius: 0;
    height: 100%;
    overflow: auto; // just in case keep global scrollbar for some edge cases
    .card {
      border: 0;
    }

    .modal-header {
      border: 0;
      border-radius: 0;
      height: 70px;

      h5 {
        flex: 1;
        font-weight: 300;
      }
    }

    .modal-body {
      overflow: auto;
      scrollbar-width: thin;
      max-height: calc(100vh - 260px);
    }

    form {
      display: flex;
      flex: 1;
      flex-direction: column;

      td input {
        border: 0;
        width: 100%;

        &[type="number"] {
          text-align: right;
        }
      }
    }

    .related-verifications {
      margin-bottom: 0.5rem;

      button {
        margin-right: 1rem;
      }
    }

    .verification-container {
      flex: 1;
      overflow: hidden;

      &.row,
      & .row {
        --bs-gutter-x: 0;

        > div > div {
          padding-left: 12px;
          padding-right: 12px;
        }

        .modal-body {
          padding: 1rem 1rem 1rem 0;
        }

        > * {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .transactions {
        padding-left: 12px;

        &-label {
          padding: 10px 0;
        }

        .table-bordered,
        .table-bordered .summary,
        .table-bordered .error {
          border-width: 0;
        }
      }

      .file-archive-attachments {
        background: #f7f7f7;
        padding: 15px;
        color: #585858;

        .body {
          background-color: white;
        }

        .search-input-wrapper {
          padding-top: 20px;
        }

        .show-hide {
          font-size: 12px;

          i {
            padding-right: 5px;
          }
        }

        .btn-attach-archive {
          color: #0B1957;
          align-content: center;
          text-align: center;
          background-color: white;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .btn-attach {
          color: black;
        }

        .icon-unchecked {
          color: #0B1957;
        }

        .icon-checked {
          color: #6bd481;
        }

        input {
          border: 0;
        }

        .title {
          font-weight: bold;
          display: contents;

          .checked {
            background-color: rgba(52, 140, 212, 0.07);
          }
        }

        .file-type {
          color: grey;
          display: contents;
        }

        table {
          .created {
            color: grey;
            width: 145px;
            text-align: right;
          }
        }
      }
    }

    .verification-details {
      table {
        &.table-bordered td,
        &.table-bordered th {
          border-color: #dee2e6;
        }

        th,
        td {
          vertical-align: middle;
          padding: 0.3rem;
        }

        tbody {
        }

        tfoot {
          .checkbox {
            margin-left: 20px;

            input {
              display: none;
            }
          }
        }
      }

      .addTransBtn {
        padding: 0 0.3rem 0 0.3rem;
        margin-left: 1rem;
      }

      .transactions tr td {
        padding: 0;

        &.select > * {
          max-height: 36px;
          overflow: hidden;
        }

        &.disabled {
          background: #f1f5f7;
        }
      }

      .periodisation-actions-group-buttons {
        .line {
          width: 8px;
          height: 0;
          border: 1px solid #c4c4c4;
          margin-top: 16px;
          margin-left: 4px;
          margin-right: 4px;
          display: inline-block;
          color: #c8cee4;
        }

        button {
          display: flex;
          align-items: center;
          flex-direction: row;
          background-color: white;
          color: #22429a;
          border-color: #c8cee4;

          &:nth-child(-n + 3) {
            border-bottom-left-radius: 7px;
            border-top-left-radius: 7px;
          }

          &:last-child,
          &:first-child {
            border-bottom-right-radius: 7px;
            border-top-right-radius: 7px;
          }

          &:hover {
            background-color: rgba(69, 110, 224, 0.1);
          }

          i {
            font-size: 14px;
          }
        }
      }

      .periodisation-row tr {
        td {
          background-color: rgba(52, 140, 212, 0.2);
          border: none;
          padding: 10px !important;

          &.disabled {
            background: rgba(52, 140, 212, 0.2) !important;
          }
        }

        .label {
          width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-align: right;
        }
      }

      tr td.periodisation-toogle {
        width: 60px;
        max-width: 60px;
        padding: 0;
        margin: 0;

        i {
          font-size: 22px;
          color: $primary;
        }
      }

      tr td.per-arrow {
        border-left: 0 solid;
        width: 20px;
        max-width: 20px;
        padding: 0;
        text-align: left;

        i {
          font-size: 18px;
          color: $primary;
        }
      }

      tr td.has-errors {
        padding: 5px;
        background: rgba(255, 0, 0, 0.1);

        ul {
          //padding-inline-start: 25px;
          padding-inline-start: 0;
          margin: 0;

          li {
            line-height: 1;
          }
        }
      }

      tr.error {
        td {
          padding: 0.5rem;
        }
      }

      tr.summary {
        td {
          padding: 0.5rem;
        }

        td:not(:last-child) {
          border: 0;
          padding-bottom: 0;
        }

        td:last-child {
          padding-top: 0;
          padding-bottom: 0;
          vertical-align: middle;
          text-align: right;
        }
      }

      table tfoot {
        td.has-error {
          border-left: 4px solid red;
        }
      }
    }
  }

  .history-btn, .pay-spec-btn {

    &.btn-sm {
      font-size: 0.8125rem;
      padding: 0.45rem 0.9rem;
      line-height: 1.5;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }

    margin-top: 23px;
  }

}

.results-modal {
  max-width: 800px;

  thead th {
    border-top: 0;
  }

  .status-wait,
  .status-ok,
  .status-fail {
    text-transform: uppercase;
    font-weight: 600;
  }

  .status-ok {
    color: $success;
  }

  .status-wait {
    color: $warning;
  }

  .status-fail {
    color: $danger;
  }
}

#bookCIForm, #bookSIForm {
  legend {
    font-size: 1rem;
    border-bottom: 1px solid $primary;
  }
}

.rot-rut-modal {
  th.disabled {
    background: $gray-100;
  }

  table.table {
    td.select {
      .table-select__control {
        padding-right: 0;

        .table-select__indicators {
          .table-select__indicator-separator,
          .table-select__dropdown-indicator {
            display: block;
          }

          .table-select__indicator-separator {
            padding: 0;
          }
        }
      }
    }
  }

  .rr-row {
    display: flex;
    flex-direction: row;
  }
}

.register-company-modal, .company-access-modal {
  .modal-lg {
    max-width: 900px;
  }
}

.select-company-modal {
  .modal-body {
    width: 500px;
    margin-left: 150px;
  }

  .search-input-wrapper {
    width: 179px;
    margin-bottom: 20px;
  }

  table.table tr.checked {
    background: rgba(52, 140, 212, 0.1);
  }

  table.table th, table.table td {
    border: 1px solid #DFE2E6;
    width: auto;

    &.company-name {
      color: $primary;
    }

    &.org-number {
      width: 180px;
    }

    i {
      margin-right: 10px;
      color: $softGreen;
    }
  }

  p {
    margin: 0px;
    text-align: center;

    a.office-support-link {
      color: $softOrange;
      font-weight: 600;
    }
  }

  .btn-link {
    color: $darkGrey;
  }
}