//
// reboot.scss
//

// a
a:focus {
  outline: none;
}

// Forms
label {
  font-weight: $font-weight-medium;
  font-size: 14px;
}

// Buttons
button:focus {
  outline: none;
}

hr {
  opacity: 1;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid $gray-300;

  &.blockquote-reverse {
    border-left: 0;
    border-right: 4px solid $gray-300;
    text-align: right;
  }
}

// list group

.list-group-item {
  color: $gray-600;
  &:hover {
    color: $gray-600;
  }
}

.list-group-flush {
  .list-group-item:first-child {
    border-top-width: 1px;
  }
}

b,
strong {
  font-weight: $font-weight-semibold;
}

.text-muted {
  color: #98a6ad !important;
}
