.week-picker {
  width: 100%;
  font-size: 1.2em;

  table {
    border-spacing: 0;
    border-collapse: separate;
    width: 100%;

    td {
      padding: 0;
    }
  }

  .rdp-caption_label {
    font-weight: 500;
  }

  .rdp-month {
    width: 100%;

    &_caption {
      align-items: end;
      margin-left:20px
    }
  }

  .rdp-months {
    max-width: none;
  }

  .rdp-button_previous,
  .rdp-button_next {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;
    content: "";
    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8b9898;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    > svg {
      visibility: hidden;
    }
  }

  .rdp-button_previous {
    margin-right: 1.5em;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
  }

  .rdp-button_next {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
  }

  .rdp-outside {
    opacity: 1;
  }

  .rdp-today {
    font-weight: 700;
    color: #6c757d;
  }

  .rdp-weekday {
    border-bottom: 1px solid #eaecec;
    font-size: .875em;
    font-weight: 400;
    color: #8b9898;
    opacity: 1;
  }

  .rdp-week {
    .rdp-day {
      &_button {
        border: 0;
        cursor: default;
        height: 100%;
        width: 100%;
      }
    }

    &_number {
      border: 0;
      border-right: 1px solid #eaecec;
      border-radius: 0;
    }
  }

  .hover-range {
    background: #eee;
    opacity: 0.9;
    border: 0 solid #888;
    border-width: 1px 0;

    &-start {
      border-left-width: 1px;
    }

    &-end {
      border-right-width: 1px;
    }
  }

  .selected {
    border: 0 solid #dc3545;
    border-top-width: 1px;
    border-bottom-width: 1px;
    opacity: 0.9;

    &-range-start {
      border-left-width: 1px;;
    }

    &-range-end {
      border-right-width: 1px;;
    }
  }

  .unlocked {
    background-color: #3788d8;
    color: #fff;
  }

  .locked {
    background: #28a745;
    color: #fff;
  }


  .future {
    color: #444;

  }
}

