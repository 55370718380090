@import "assets/scss/new_ui/colors";

.onboarding-confirmation-modal {
  .modal-content {
    border-radius: 10px;
    padding: 20px;
    width: 75%;
  }

  .modal-header {
    padding: 24px;
    border-bottom: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: $blue-100;
  }

  .modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 22px;
    color: $grey-100;

    p, ul {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $grey-100;
    }

    ul {
      padding-left: 16px;
      font-weight: 500;

      li {
        font-weight: 400;
      }
    }
  }

  .modal-footer {
    padding: 24px;
    border-top: none;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  .form-check {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: $grey-100;
  }
}
