.connection__accounts {
  margin-top: 20px;
  margin-bottom: 30px;
  list-style-type: none;
  padding: 0;

  li {
    color: #585858;
    margin-top: 12px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .form-switch {
    padding-left: 0;
    .form-check-label:before {
      //background-color: red;
      content:'';
    }
    .form-check-input:disabled ~ label {
      color: #98a6ad;
      opacity: 1;
    }

    input{
      border-color: #0B1957;
    }

    label {
      font-size: 13px;
      font-weight: 400;

      span {
        color: #0B1957;
        font-size: 11px;
      }
    }
  }

  .bflow-account {
    cursor: pointer;
    border: 0;
    color: #585858;
    font-weight: 600;

    &:hover {
      color: #000;
    }

    i {
      margin-left: 4px;
      font-size: 16px;
      color: #0B1957;
    }
  }

  .balance {
    color: #ADADAD;
    font-weight: 400;
  }
}