.connection-results {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #6BD581;
    font-weight: 600;
    font-size: 14px;
  }

  .custom-switch {
    border-color: #adb5bd;
    .indicator {
      background-color: #adb5bd;
    }

    &.checked {
      background-color: #0B1957;
      border-color: #0B1957;

      .indicator {
        transform: translateX(85%);
        background-color: white;
      }
    }
  }

  button:not(.custom-switch) {
    padding: 5px 40px;
    border-radius: 5px;
  }
}