@import "assets/scss/new_ui/colors";

.status-list {
  .nav-item {
    a {
      font-weight: 500;
    }

    a.active {
      color: #456EE0;
    }
  }
}

#status-list-table {
  min-height: 500px;
  width: 100%;

  .btn-filter {
    max-height: 36px;
    margin-top: 21px;
  }

  .table-responsive {
    padding-bottom: 400px;
  }

  .sticky-wrapper {
    position: relative;
    max-width: 100%;
    width: 100%;
    overflow: auto;
    max-height: calc(100vh - 430px);
  }

  table {
    background: #fff;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;

    thead th {
      position: sticky;
      top: -1px;
      background: #fff;
      z-index: 1;
    }

    thead th:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
      width: 300px;

    }

    tbody th:first-child {
      position: sticky;
      left: 0;
      background: white;
      z-index: 1;

      i {
        visibility: hidden;
        color: #456EE0;
        font-size: 14px;
        font-weight: 500;
      }

      &:hover {
        background: #AAB0C61A;

        i {
          visibility: visible;
        }
      }
    }

  }

  tr {
    border-top: 1px solid #ccc;
  }

  td, th {
    text-align: left;
    width: 200px;
    vertical-align: top;
    padding: 8px;
    background: #fff;
  }

  .company-btn {
    color: #0B1957;
    font-weight: 600;

    i {
      visibility: hidden;
    }

    &:hover {
      color: #456EE0;

      i {
        visibility: visible;
      }
    }
  }

  .initials {
    display: flex;
    flex: 1;
    color: #0B1957;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    align-content: center;

    &__badge {
      &:nth-child(1) {
        z-index: 8;
      }

      &:nth-child(2) {
        z-index: 7;
      }

      &:nth-child(3) {
        z-index: 6;
      }

      &:nth-child(4) {
        z-index: 5;
      }

      &:nth-child(5) {
        z-index: 4;
      }

      &:nth-child(6) {
        z-index: 3;
      }

      width: 26px;
      height: 26px;
      border-radius: 15px;
      border: 1px solid #AAB0C6;
      margin-right: -3px;
      background: #fff;
      display: flex;
      z-index: -1;
      justify-content: center;
      align-items: center;


    }
  }

  .event-col {
    &.event-done {
      background: $green-6;
    }

    &.disabled {
      background: #ECEDED;
    }

    .top {
      //display: flex;
      //justify-content: space-between;
      //align-items: center;
    }

    .bottom {
      margin-top: 4px;
      display: flex;
      justify-content: end;
      cursor: pointer;
      border-radius: 4px;
      flex: 1;


      &:hover {
        background: $blue-6;

        .bottom-badge, .initials__badge {
          display: none;
        }

        .bottom-badge-open {
          display: block;
        }
      }

      .bottom-badge-open {
        display: none;
      }

      .bottom-badge {
        line-height: 1.2;
        font-size: 15px;
        color: $blue-80;
        margin-right: .4rem;

        &:hover {
          text-decoration: none;
        }
      }

      .initials__badge {
        color: $grey-80;
        border-color: $grey-40;
        font-size: 9px;
        margin-right: 4px;
        font-weight: 600;
        width: 17px;
        height: 17px;
        background: transparent;
        z-index: auto;
      }
    }

    .comment-btn {
      position: relative;

      &.has-comments {
        .dot-indicator {
          display: block;
        }
      }

      .dot-indicator {
        display: none;
        width: 6px;
        height: 6px;
        position: absolute;
        right: 0;
        border: 1px solid white;
        top: 3px;
        background: #456EE0;
        border-radius: 5px;
      }
    }
  }

  .no-results {
    text-align: center;
    margin-top: 3rem;

    h3 {
      color: #0B1957;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      margin: 0;
    }

    h4 {
      color: #313144;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;

      a {
        font-weight: 600;
        color: #313144;
        text-decoration: underline;
      }
    }
  }
}