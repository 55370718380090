.text-black {
  color: $text-black;
}

.ws-preline {
  white-space: pre-line;
}

.ml-auto {
  margin-left: auto;
}