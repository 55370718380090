//
// modal.scss
//

// Title text within header
.modal-title {
  margin-top: 0;
}

// Full modal
.modal-full {
  width: 92%;
  max-width: none;
}

// Custom animation modal
.modal-demo {
  background-color: $white;
  width: 600px !important;
  border-radius: 4px;
  display: none;
  position: relative;
  .close {
    position: absolute;
    top: 15px;
    right: 25px;
    color: $light;
  }
}

@media (max-width: 768px) {
  .modal-demo {
    width: 96% !important;
  }
}

.custom-modal-title {
  padding: 15px 25px 15px 25px;
  line-height: 22px;
  font-size: 18px;
  background-color: $dark;
  color: $light;
  text-align: left;
  margin: 0;
}

.custom-modal-text {
  padding: 20px;
}

.custombox-modal-wrapper {
  text-align: left;
}

.custombox-modal-flash,
.custombox-modal-rotatedown {
  .close {
    top: 20px;
    z-index: 9999;
  }
}

.kyc-modal {
  th,
  td {
    border: 1px solid #e5e5e5 !important;
  }
}