@import "assets/scss/new_ui/colors.scss";

.reports {
  .table-loader-inner {
    align-items: flex-start;
    margin-top: 5%;
  }

  table {
    tbody tr:hover,
    th,
    td {
      padding: 0.5rem 0.85rem;
      vertical-align: middle;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
    }

    th {
      line-height: 14px;
    }

    th:not(:first-child),
    td:not(:first-child) {
      text-align: right;
    }


    .parent-category--name th,
    .parent-category--sum th {
      font-weight: 600;
      font-size: 1rem;
      color: $blue-gr2;
      padding-bottom: .7rem;
      padding-top: .7rem;
    }

    .parent-category--name th {
      padding-top: 1rem;
      padding-bottom: 1rem;
      letter-spacing: .5px;
      font-size: 14px;
    }

    .parent-category--sum th {
      background: $purple-4;
      color: $purple-80;
      font-size: 14px;
      letter-spacing: .5px;
    }

    .child-category--name th,
    .child-category--sum th,
    .extra-category th {
      font-weight: 600;
      font-size: 14px;
      background: $blue-4;
      color: $blue-80;
    }

    .child-category--name th {
      padding: .7rem 0.85rem
    }

    .child-category--sum th {
      background: transparent;
      color: $purple-80;
      border-top: 2px solid $purple-10;
      padding-bottom: 2rem;
    }

    .child-category--sum.last th {
      padding-bottom: .7rem;
      padding-top: .7rem;
    }

    thead th {
      border-bottom: 2px solid $success;
    }

    tfoot th {
      border-top: 2px solid $success;
      font-weight: 600;
      font-size: 14px;
    }

    button.btn-link {
      text-align: left;
    }

    .inner-row {
      td {
        background-color: rgba(52, 140, 212, 0.1);
      }
    }

    &.compare-budget {
      .budget-ok {
        background: rgba(107, 212, 129, 0.15);
      }

      .budget-fail {
        background: rgba(253, 103, 103, 0.12);
      }
    }
  }

  .reconciliation-info-button {
    display: inline-block;
    cursor: pointer;

    i {
      padding-right: 0.5rem;
      margin-right: 0.5rem;
      font-size: 14px;

      &.fa-square {
        color: $red-60;
      }

      &.fa-check-square {
        color: $success;
      }
    }
  }

  .ledger-table {
    .tran-title {
      display: block;
      font-size: 11px;
      color: #757575;
    }
  }
}
