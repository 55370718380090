input,
textarea,
select,
.react-select,
.checkbox input[type="checkbox"]:not(:checked) + label::before,
.radio input[type="radio"]:not(:checked) + label::before {
  border-color: $gray-400 !important;
  border-width: 1px;
}

.checkbox input[type="checkbox"]:not(:checked) + label::before,
.radio input[type="radio"]:not(:checked) + label::before {
  border-width: 2px;
}

.react-datepicker-popper {
  z-index: 99 !important;

  &.popper-in-modal {
    z-index: 9999 !important;
  }
}

.form-group {
  label {
    margin-bottom: 0.2rem;

    &.form-label {
      display: block;
    }
  }

  &.is-invalid {
    input,
    textarea {
      border-color: $danger !important;
      //padding-right: calc(1.5em + 0.9rem);
      //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f7531f' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f7531f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
      //background-repeat: no-repeat;
      //background-position: right calc(0.375em + 0.225rem) center;
      //background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
    }

    .react-select {
      .select__control {
        border-color: $danger !important;
      }
    }

    .react-datepicker-wrapper input {
      background: none;
    }

    .invalid-feedback {
      display: block;
    }
  }
}

.money-input {
  text-align: right;
  height: 36px;
}
td .money-input {
  border-radius: 0;
}

.search-input-wrapper {
  position: relative;

  .search-input {
    padding-left: 2.3rem;
    width: 15rem !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  i {
    position: absolute;
    top: 11px;
    left: 12px;
    color: #ced4da;
  }
}

.fyear-react-select {
  $pickerColor: #fff;
  $pickerColorHover: #fff;
  width: 270px;
  display: inline-block;
  padding-right: 1.5rem;
  margin-right: 0.5rem;
  border-right: 1px solid #dadada;

  .select__single-value:before {
    font-family: "feather" !important;
    content: "\e83a";;

    padding-right: 0.5rem;
  }

  .select__control {
    cursor: pointer;
    background: transparent;
    border-color: $pickerColor;
    border-radius: 4px;
    min-height: auto;
    height: 33px;
    line-height: initial;

    color: $pickerColor;

    .select__value-container {
      padding: 0 8px;
    }

    .select__single-value {
      color: $pickerColor;
    }

    .select__indicators {
      .select__indicator-separator {
        margin: 7px 0;
        background-color: $pickerColor;
      }

      .select__indicator {
        color: $pickerColor;
        padding: 0 8px;
      }
    }
  }

  &:hover {
    .select__control {
      border-color: $pickerColorHover;

      .select__single-value {
        color: $pickerColorHover;
      }

      .select__indicators {
        .select__indicator-separator {
          background-color: $pickerColorHover;
        }

        .select__indicator {
          color: $pickerColorHover;
        }
      }
    }
  }
}

.react-select {
  .select__control {
    height: 35.89px;
    min-height: auto;

    &.select__control--is-focused {
      box-shadow: none;
    }
  }

  .select__single-value {
    color: $gray-700;
  }

  .select__single-value,
  .select__placeholder {
    top: 45%;
  }

  .select__placeholder {
    color: $gray-600;
  }

  .select__menu {
    z-index: 999;
  }
}

.react-select.multi-select {
  width: auto !important;
  min-width: 15rem;
  max-width: 30rem;

  .select__control {
    height: auto !important;
  }
}

.react-datepicker-wrapper {
  display: block;

  .react-datepicker__close-icon {

    &::after {
      background: $blue-80;
    }
  }
}

.number-range {
  display: flex;
  flex-direction: row;

  input {
    width: 150px;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.account-range {
  .react-select {
    width: 180px !important;

    &:nth-child(1) {
      .select__control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:nth-child(2) {
      .select__control {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }
}

.date-range {
  display: flex;
  flex-direction: row;

  .react-datepicker-wrapper {
    input {
      width: 150px;
    }

    &:first-child input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.upload-btn {
  input[type="file"] {
    display: none;
  }
}

table.table {
  &.table-form {
    td {
      padding: 0;
      margin: 0;
      vertical-align: middle;

      &.disabled {
        background: #f1f5f7;
      }
    }

    input {
      border: 0;
    }
  }

  .table-select {
    //z-index: 3;
    .table-select__control {
      border: 0;
    }

    .table-select__control--is-focused {
      box-shadow: none;
    }
  }

  .table-select__indicators {
    .table-select__indicator-separator,
    .table-select__dropdown-indicator {
      display: none;
    }

    .table-select__clear-indicator {
      padding: 0;
    }
  }

  .table-select__menu {
    width: 360px;
  }

  td.select {
    padding: 0 !important;

    .table-select__control {
      padding-right: 8px;
      border-radius: 0;
    }
  }
}

.custom-control {
  z-index: auto !important;
  padding-left: 1.5rem;

  &:has(label:empty) {
    margin-top: -25px !important;
  }

  &-inline {
    display: inline-flex;
    margin-right: 1rem;
  }
}

div.checkbox.custom-control.checkbox-primary {
  label {
    margin-bottom: 0;
  }
}

.checkbox.checkbox-gray input[type=checkbox]:checked + label::before {
  background-color: #22429A;
  border-color: #22429A;
}

.checkbox.checkbox-gray input[type=checkbox]:checked + label::after {
  border-color: #fff;
  border-bottom-width: 1px;
  border-right-width: 1px;
}

#manual-booking-form,
#bank-rules-form {
  &.table,
  tr {
    border: 0;
  }
  td {
    border-width: 1px;
  }
  tfoot {
    tr:last-child td:first-child {
      border: 0;
    }

    tr td {
      padding: 0.5rem;
    }
  }

  th,
  td {
    vertical-align: middle;
    padding: 0.3rem;
  }

  tr td {
    padding: 0;

    &.select > * {
      max-height: 36px;
      overflow: hidden;
    }
  }

  td input {
    border: 0;
    width: 100%;

    &[type="number"] {
      text-align: right;
    }
  }
}

#bank-rules-form {
  tr:hover {
    background: transparent !important;
    box-shadow: none;
  }

  tr td {
    &.select > * {
      max-height: inherit;
      overflow: auto;
    }
  }

  tr td.has-errors {
    padding: 5px;
    background: rgba(255, 0, 0, 0.1);

    ul {
      //padding-inline-start: 25px;
      padding-inline-start: 0;
      margin: 0;

      li {
        line-height: 1;
      }
    }
  }
}

.multi-email-field {
  display: flex;

  .react-select {
    flex: 1;

    .select__control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: auto;
    }
  }

  button.btn {
    border-radius: 0;
    height: 33px;
    border-left: 0;
  }
}

.user-picker {
  .form-group {
    width: calc(100% - 44px);

    .select__control {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .input-group-append {
    margin-top: 24px;

    button {
      height: 36px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.customer-picker {
  display: flex;

  .customer-select {
    & > div {
      border-radius: 0;
      border-color: #6c757d;
    }
  }

  > .form-group {
    flex: 1;

    .customer-select {
      width: 100% !important;
    }
  }

  > .btn {
    height: 38px;
    margin-top: 24px;
    border-left: 0;
    border-radius: 0;
  }
}

.currency-picker {
  .form-group {
    margin-bottom: 0;
  }

  margin-bottom: 1rem;

  > small {
    font-size: 0.7rem;
    padding-left: 0.3rem;
    letter-spacing: 1px;
  }
}

.reconciliation-input {
  background-color: transparent;
  font-weight: 500;
  text-align: right;
  padding: 0;
  border: none;

  &:focus {
    background-color: transparent;
  }
}

.no-options-message-container {
  position: relative;

  .no-options-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

#assessment {
  tr.has_errors {
    background: rgba(249, 54, 54, 0.08);

    p {
      margin-bottom: 0;
      font-weight: 500;
      color: #f93636;
    }
  }

  tr.break {
    td {
      border-top: 2px solid $primary;
    }
  }

  td {
    padding: 0.5rem;
    vertical-align: top !important;
  }

  td:first-child {
    max-width: 800px;
    min-width: 600px;
  }

  td input {
    padding: 0;
    border: 0;
  }

  td {
    .autoHeightAreaEditable {
      color: grey;

      &:disabled {
        opacity: 0.65;
        background-color: transparent;
      }
    }
  }

  .description-container {
    width: 100%;
    border: 1px solid #f5f3f3;
    padding: 6px;
    margin-bottom: 20px;

    .autoHeightArea {
      width: 100%;
      min-height: 120px;
      color: grey;
    }

    .autoHeightAreaEditable {
      &:disabled {
        opacity: 0.65;
        background-color: transparent;
      }
    }
  }

  td label {
    margin-bottom: 0;
  }
}

.faked-input {
  font-size: 13px;
  position: absolute;
  top: 8px;
  right: 10px;
}

.autoHeightArea {
  word-break: keep-all;
  overflow: hidden;
  white-space: pre-line;
  width: 300px;
}

.autoHeightAreaEditable {
  width: 100%;
  border: none;
  resize: none;
  color: grey;

  &:focus {
    outline: none;
  }
}

.inline-options-select {
  .label {
    margin-bottom: 0.2rem;
  }

  &:disabled {
    //opacity: 0.65;
    background-color: transparent;
  }

  &:focus {
    outline: none;
  }
}

.inventory-register {
  &.asset-form {
    & > .row {
      padding-top: 12px;
      padding-left: 4px;
    }

    .partial-update-col-base {
      background-color: #f7f7f7;
      border-radius: 12px;
      padding-top: 12px;
      margin-top: -12px;
    }

    .partial-update-col {
      & > .row {
        position: relative;
      }

      &.hover-enabled:hover {
        @extend .partial-update-col-base;
      }
    }

    .partial-update-col {
      &.active {
        @extend .partial-update-col-base;
        padding-bottom: 12px;
        border: 1px solid #0B1957;
        border-radius: 4px;
      }
    }

    .fe-edit-2 {
      width: unset;
      position: absolute;
      right: 7px;
      top: 0;
      color: #456ee0;

      &:hover {
        cursor: pointer;
      }

      &.inactive {
        color: #adadad !important;
        pointer-events: none;
      }
    }
  }

  .btn-depreciation-preview {
    border-color: #e5e5e5;

    i:first-child {
      color: #0B1957;
    }

    padding-right: 4px;

    .separator {
      content: "";
      display: inline-block;
      background: #e5e5e5;
      margin: -8px 2px -8px 18px;
      height: 36px;
      width: 1px;
      vertical-align: top;
    }
  }
}

