@import "assets/scss/new_ui/colors";

table.table {
  color: #6c757d;

  &-sm {
    td,
    th {
      padding: 0.5rem
    }
  }

  tbody {
    tr.draft,
    tr.new {
      border-left: 8px solid $draft;
    }

    tr.disputed {
      border-left: 8px solid $disputed;
    }

    tr.investigate {
      border-left: 8px solid $investigate;
    }

    tr.prelim {
      border-left: 8px solid $prelim;
    }

    tr.unpaid {
      border-left: 8px solid $unpaid;
    }

    tr.expired {
      border-left: 8px solid $expired;
    }

    tr.sent_bank {
      border-left: 8px solid $sentBank;
    }

    tr.paid,
    tr.approved,
    tr.booked {
      border-left: 8px solid $paid;
    }

    tr.voided {
      border-left-color: #ccc;
    }

    tr.denied {
      border-left: 8px solid lightgrey;
    }

    tr.empty {
      border-left: 8px solid white;
    }

    tr.active {
      border-left: 8px solid $active;
    }

    tr.inactive {
      border-left: 8px solid $inactive;
    }

    tr {
      &:last-child {
        td {
          border-bottom-width: 0;
        }
      }

      td.warning-corner {
        &::after {
          position: absolute;
          top: 0;
          content: "";
          width: 10px;
          border-bottom: 10px solid transparent;
          border-right: 10px solid $warning;
          right: 0;
          height: 10px;
        }
      }
    }

    tr.has-message {
      td:last-child {
        &::after {
          position: absolute;
          top: 0;
          content: "";
          width: 10px;
          border-bottom: 10px solid transparent;
          border-right: 10px solid $blue-30;
          right: 0;
          height: 10px;
        }
      }
    }

    tr.has-problem {
      td:last-child {
        &::after {
          position: absolute;
          top: 0;
          content: "";
          width: 10px;
          border-bottom: 10px solid transparent;
          border-right: 10px solid $danger;
          right: 0;
          height: 10px;
        }
      }
    }

    tr.has-auditor {
      td:last-child {
        &::after {
          position: absolute;
          top: 0;
          content: "";
          width: 10px;
          border-bottom: 10px solid transparent;
          border-right: 10px solid #bc7bbf;
          right: 0;
          height: 10px;
        }
      }
    }

    tr.muted {
      & > td {
        color: #98a6ad;

        > a {
          color: inherit;
        }
      }
    }

    tr.archived {
      & > td {
        background-color: #e6e6e6;

        > a {
          color: inherit;
        }
      }
    }

    .btn-link, a {
      color: $blue-gr2;

      &:hover {
        color: $blue-30;
        text-decoration: underline;
      }
    }
  }

  &.table-bordered {
    th,
    td {
      border-color: #eaebeb;
    }

    th {
      border-bottom: 0;
    }

    td.single-has-error {
      background: rgba(253, 103, 103, 0.1);
      border: 2px solid #FFC1C1;;

      .react-datepicker-wrapper {
        background: #fff;

      }

      input {
        background: rgba(253, 103, 103, 0.1);
      }

      .table-select {
        background: #fff;

        .table-select__control {
          background: rgba(253, 103, 103, 0.1);
        }
      }
    }
  }

  &.table-hover {
    tbody {
      tr {
        position: relative;

        td {
          border-color: $grey-8;
        }
      }

      tr:hover:not(.expanded) {
        background-color: $grey-8;
        transition-duration: 0.15s;
        transition-property: background-color;
        box-shadow: 3px 4px 4px $grey-10;
      }
    }
  }

  &.table-striped {
    tbody tr:not(.expanded):not(:hover):nth-of-type(odd) {
      background: rgba(0, 0, 0, 0.02);
    }
  }

  &.sticky-header {
    position: relative;
    z-index: 3;

    thead {
      //box-shadow: 0 1px 0 0 rgba(40, 167, 69, 1);
    }

    thead th {
      box-shadow: inset 0 -2px 0 $success;
      background: #fff;
      position: sticky;
      top: 69px;
      z-index: 4;
    }

    .sticky1 th,
    .sticky2 th {
      z-index: 4;
      position: sticky;
      background: #fff;
    }

    .sticky1 th {
      top: 104px;
    }

    .sticky2 th {
      box-shadow: inset 0 -1px 0 $gray-200, inset 0 1px 0 $gray-200;
      top: 139px;
    }
  }

  th,
  td {
    vertical-align: middle;
  }

  th {
    border-top: 1px solid #f5f3f3;
  }

  thead {
    th {
      border-bottom: 2px solid #f5f3f3;

      &.text-end {
        text-align: right;

        div.sortable {
          justify-content: flex-end;

          i {
            margin-left: 0.25rem;
          }
        }
      }

      &.text-center {
        text-align: center;

        div.sortable {
          justify-content: center;

          i {
            margin-left: 0.25rem;
          }
        }
      }

      div.sortable {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i.fa-sort {
          color: #dee2e6;
        }
      }
    }
  }

  tbody {
    tr:last-child {
      td.approval-flow {
        .preview {
          top: -40px;
        }
      }
    }

    td {
      &.disabled {
        background: #f1f5f7;
      }

      &.approval-flow {
        padding: 0;

        div.td-wrapper {
          text-align: center;
          padding-bottom: 10px;
          padding-top: 10px;

          i {
            font-size: medium;
          }
        }

        .preview {
          box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.2);
          position: absolute;
          z-index: 2000;
          left: 50px;
          padding: 12px;
          width: max-content;
          max-width: 800px;
          background: white;
          border-radius: 10px;

          .badge {
            &:hover {
              cursor: default;
            }

            color: $black;
            padding: 7px;
            margin: 3px;
            font-weight: 500;
            border: 1px solid $grey-40;

            &.approved {
              border: 1px solid $green-40;
              background: rgba(107, 212, 129, 0.12);
            }

            &.denied {
              border: 1px solid $purple-80;
              background: $purple-6;
            }

            &.for-approval {
              border: 1px dashed $grey-10;
              background: $orange-4;
              color: $blue-80;
            }
          }
        }
      }
    }

    tr.expanded {
      border-bottom: 2px solid $border-color;
    }

    .expand {
      width: 50px;
      text-align: center;
      cursor: pointer;
    }

    .selectable {
      cursor: pointer;
      width: 60px;

      .checkbox {
        padding: 0;
        margin: 0;

        label {
          cursor: pointer;
          margin: 0;
          padding: 0;

          &:before,
          &:after {
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    tr.removed {
      background: #f1f5f7;

      td {
        position: relative;
      }

      input,
      .table-select__control {
        background: transparent !important;
        color: #999 !important;
      }

      td:not(.td-actions):before {
        content: " ";
        position: absolute;
        top: 50%;
        background: gray;
        left: 0;
        border-bottom: 1px solid #aaa;
        width: 100%;
      }

      td button {
        position: relative;
        z-index: 2;
      }
    }

    button.btn-link {
      padding: 0;
    }

    a.table-link,
    button.table-link {
      color: #6c757d;
      font-weight: 600;
      width: 100%;
      display: block;

      i {
        visibility: hidden;
        float: right;
      }

      &:hover {
        color: $primary;

        i {
          visibility: visible;
        }
      }
    }

    button.table-link {
      line-height: 1;
      margin: 0;
      text-align: left;
      padding: 0;
    }

    tr:hover {
      .div-actions {
        opacity: 1;
        vertical-align: middle;
        transition-duration: 0.25s;
        transition-property: opacity;
      }
    }

    td {
      position: relative;
    }

    .div-actions {
      padding-right: 6px;
      padding-left: 8px;
      background: $grey-8;
      top: 7px;
      display: flex;
      justify-items: center;
      align-items: center;
      white-space: nowrap;
      opacity: 0;
      position: absolute;
      right: 0;

      button, a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        height: 32px;
        width: 32px;
        margin: 0 4px 0 0;
        vertical-align: middle;

        i {
          font-size: 12px;
        }
      }
    }

    .td-actions {
      white-space: nowrap;
      width: 1%;
      padding-top: 0;
      padding-bottom: 0;
      vertical-align: middle;
      text-align: right;

      button, a {
        margin: 0 4px 0 0;
        padding: 0;
        height: 32px;
        width: 32px;
      }
    }
  }

  td.text-left .btn-link {
    text-align: left;
  }

  tr td.has-errors {
    padding: 5px;
    background: rgba(255, 0, 0, 0.1);

    ul {
      padding-inline-start: 25px;
      margin: 0;

      li {
        line-height: 1;
      }
    }
  }

  td {
    &.td-area {
      padding: 5px 0 0 0;
      margin: 0;

      textarea {
        border: 0;
      }
    }

    .table-select {

      .table-select__control--is-disabled {
        background: #f1f5f7;
      }

    }


    &.td-datePicker {
      input {
        color: #6c757d;
        padding-left: 0;
      }


      input {
        border: 0;

      }
    }
  }
}

div.table-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pagination {
  border-radius: .25rem;
  --bs-pagination-font-size: unset;
  --bs-pagination-border-radius: 0;

  .page-item:first-child {
    .page-link {
      border-bottom-left-radius: .25rem;
      border-top-left-radius: .25rem;
    }
  }

  .page-item:last-child {
    .page-link {
      border-bottom-right-radius: .25rem;
      border-top-right-radius: .25rem;
    }
  }

  .page-link {
    line-height: 1.25;
    padding: .5rem .75rem;
  }
}

.table-loader {
  z-index: 99;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;

  .table-loader-inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
}

.table-filters-group {
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;

  label {
    font-size: 0.75rem;
    margin-bottom: 0.2rem;
  }

  .table-filters-left,
  .table-filters-right,
  section {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    > * {
      margin-bottom: 0.75rem;
      margin-right: 0.75rem;
    }

    > *:last-child {
      margin-right: 0;
    }

    .react-select {
      width: 15rem;
    }

    .react-datepicker-wrapper {
      display: block;
      width: 150px;
    }

    #checkbox {
      margin-bottom: 10px;
    }
  }

  section.more-filters {
    flex-basis: 100%;
    align-items: end;
  }

  .table-filters-left {
    flex: 1;
  }

  .table-filters-right {
  }


}

.b-table table {
  td.success {
    background: $green-6;
    color: #6c757d;

    button {
      color: #6c757d;
    }
  }

  td.warning {
    background: $orange-4;
    color: #6c757d;

    button {
      color: #6c757d;
    }
  }

  td.danger {
    background: $red-6;
    color: #6c757d;

    button {
      color: $primary;
    }
  }

  td.bg-grey {
    background: $grey-8;
  }
}

.b-table {
  position: relative;
  padding-bottom: 0;

  tr.show-row-tooltip-by-td:hover td:last-child:after {
    opacity: 1;
    content: attr(row-title);
    position: absolute;
    background: #757575;
    margin-top: -50px;
    margin-left: -600px;
    transition: all 0.15s ease;
    padding: 10px;
    color: white;
    border-radius: 10px;
    box-shadow: 2px 2px 1px silver;
    width: 370px;
    text-align: left;
  }

  tr.disabled td {
    color: #adadad;


    i {
      color: #626262;
    }
  }
}

.stats-table {
  color: black !important;
  font-size: 13px;
  font-weight: 500;
  text-align: center;

  th {
    color: black;
  }

  td.total-right-activated {
    background-color: rgba(107, 212, 129, 0.15) !important;
  }

  td.total-right-activated-tt-only {
    background-color: rgba(188, 123, 191, 0.15) !important;
  }

  tbody.nested tr {
    pointer-events: none;
    background: transparent !important;
  }

  tfoot tr.highlighted {
    td {
      background-color: rgba(52, 140, 212, 0.15);
    }
  }

  .total-separator {
    border-top-color: #0B1957 !important;
    border-top-width: 2px;
  }

  span {
    color: #0B1957 !important;
  }

  .draft {
    color: #e7992b !important;
  }
}

.agency-consult-perm {
  tr:nth-child(2n) {
    background: #ededed;
  }
}

.th-checkbox {
  > div:has(label) {
    justify-content: flex-end;
  }

  label {
    font-size: 13px;
    font-weight: 500;
  }
}